import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import {Grid} from "@material-ui/core";
import {API_CONFIG} from "../../config/api-config";
import Arrow from "../../assets/images/arrow.png";
import CloseArrow from "../../assets/images/closeArrow.svg";
const useStyles = makeStyles((theme) => ({
    list: {
        maxWidth: 360,
    },
    fullList: {
        width: 'auto',
    },
    appBar: {
        position: 'relative',
    },
    title: {

        flex: 1,
    },
    closeIcon: {
        paddingLeft: '80%',
        float: 'right',
        textAlign: 'right'
    },
    info: {
        float: 'left',
        textAlign: 'left'
    },
    drawerContent: {
        padding: '16px 16px 16px',
    },

    dialogFooter: {
        marginTop: 16,
        fontSize: 10
    }

}));


export default function HelpDrawer(props) {
    const createData = (name, val) => {

        return {name, val};
    }
    const coverRows = [

        createData('Younger than six years (including stillborn babies)', 'R5 000 - R 20 000'),
        createData('Six and older but younger than 14', 'R5 000 - R 50 000'),
        createData('14 and older but younger than 21', 'R5 000 - R 80 000'),
        //createData('Policy end date', 'Your policy ends at retirement or three months from the date at which you leave your current employer.'),
    ]
    const classes = useStyles();
    return (
        <div
            className={classes.list}

            onClick={props.toggleDrawerClose}
            onKeyDown={props.toggleDrawerClose}
        >
           {/* {props.title}*/}

             {(props.title === "Repatriation") ? (
                  <React.Fragment>
                      <div className="MuiSvgIcon-root-close-arrow-repatriation">
                          <svg
                               focusable="false"
                               viewBox="0 0 24 24"
                               aria-hidden="true" ><path
                              d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" ></path></svg>
                      </div>
                  </React.Fragment>
              ) : ((props.title === "Payment protection at age 85 benefit") ? (
                   <React.Fragment>
                       <div className="MuiSvgIcon-root-close-arrow-protection">
                           <svg
                                focusable="false"
                                viewBox="0 0 24 24"
                                aria-hidden="true" ><path
                               d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" ></path></svg>
                       </div>
                   </React.Fragment>
             ) : ((props.title === "Keep up with inflation") ? (
                     <div className="MuiSvgIcon-root-close-arrow-value-protection">
                         <svg
                              focusable="false"
                              viewBox="0 0 24 24"
                              aria-hidden="true" ><path
                             d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" ></path></svg>
                     </div>
             ) : ((props.title === "Prominent Influential Person (PIP)") ? (
                 <div className="MuiSvgIcon-root-close-arrow-pip">
                     <svg
                         focusable="false"
                         viewBox="0 0 24 24"
                         aria-hidden="true" ><path
                         d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" ></path></svg>
                 </div>


             ) : ((props.title === "Child For Life") ? (
                     <div className="MuiSvgIcon-root-close-arrow-child">
                         <svg
                             focusable="false"
                             viewBox="0 0 24 24"
                             aria-hidden="true" ><path
                             d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" ></path></svg>
                     </div>

             ):("")))))}


            <div className={classes.drawerContent}  >
                <Typography variant="h4" component="h4" display="block" gutterBottom align="left"
                            className="Drawer-sub-heading">

                    {(props.title !== "Help")  &&  (props.title !== "Child For Life") ? (
                        <React.Fragment>{props.title}</React.Fragment>
                        ):("")
                    }
                 </Typography>

                {((props.dialogContent.length > 0)) ? (
                    <React.Fragment>


                        {(props.clickedItem === "cashbackBenefit") ? (
                            <React.Fragment><p className={classes.mainContent}>This optional benefit pays you back a
                                proportion of your premiums paid after reaching the following milestones:</p>
                                <ul className={classes.mainContent}>
                                    <li>After you pay your first 12 premiums, you will get 2 premiums back in cash.</li>
                                    <li>Thereafter, you will receive 6 premiums back in cash for every 36 premiums
                                        paid.
                                    </li>
                                </ul>
                            </React.Fragment>) : (props.dialogContent)}
                    </React.Fragment>
                ) : ((props.title === "Children and relatives cover levels") ? (
                    <div>
                        Cover amount limits are set based on a child's age for this product. Please see the table below
                        for a breakdown.
                        <Grid container direction="row" className={"accordionContent"}>

                            <Grid item md={12} xs={12}>

                                <Typography variant={"h4"} component={"h4"} className={"summaryProductNameHeading"}>Metropolitan
                                    Funeral Cover Limits</Typography>
                                {(coverRows).map((coverRow, index) => (

                                    <Grid container justify="space-between" direction="row"
                                          key={`coverLimitRow_${index}`}>
                                        <Grid item md={6} xs={6}><span
                                            className="summaryLabelsHeading">{coverRow.name}</span></Grid>
                                        <Grid item md={6} xs={6}><span
                                            className="summaryLabels">{coverRow.val}</span></Grid>
                                    </Grid>
                                ))}


                            </Grid>
                        </Grid>
                    </div>
                ) : ((props.title === "Help") ? (
                    <div>
                    <p className="Drawer-heading">Waiting Periods</p>
                    <p className="Drawer-sub-heading">When your cover kicks in:</p>
                  <div className="grid-container-element">
                                 <div className="grid-child-element"> <img src={Arrow} className="arrow"/> </div>
                                  <div className="grid-child-element"> There is <b>no waiting period</b> for death claims if the death is the direct result of an accident.</div>
                                  <div className="grid-child-element"> <img src={Arrow} className="arrow"/> </div>
                                  <div className="grid-child-element">There’s a <b>three-month waiting period</b> for death by natural causes for you, the plan owner, your life partner, & children.</div>
                                  <div className="grid-child-element"> <img src={Arrow} className="arrow"/> </div>
                                  <div className="grid-child-element">There’s a <b>six-month waiting period</b> for death by natural causes for your parents and extended family members.</div>
                                  <div className="grid-child-element">  <img src={Arrow} className="arrow"/> </div>
                                  <div className="grid-child-element">There's a <b>one year waiting period</b> if death is the result of suicide.</div>
                          </div>


                        <div className="MuiSvgIcon-root-close-Arrow">
                        <svg className="MuiSvgIcon-root-close-Arrow-color"
                             focusable="false"
                             viewBox="0 0 24 24"
                             aria-hidden="true" ><path
                            d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" ></path></svg>
                        </div>
                    </div>

                ) : ((props.title === "Child For Life") ? (



                 /*   <div>If your child cannot be insured under Children cover, the child can be insured under Child for live cover, A child can only be insured on Children cover or Child for life cover but not both. Financially independent and married children can be insured under Child for life cover. You have
                        to let Metropolitan know if you want to do this. Your premium may change if you make this change
                        to your plan.</div>*/
                 <div>
                     <p className="Drawer-heading"> Children cover </p>
                     <p className="Drawer-sub-heading">To be insured, your child has to be:</p>
                     <div className="grid-container-element">
                         <div className="grid-child-element"> <img src={Arrow} className="arrow"/> </div>
                         <div className="grid-child-element">Younger than 21 years </div>
                         <div className="grid-child-element"> <img src={Arrow} className="arrow"/> </div>
                         <div className="grid-child-element">21-years or older but younger than 26-years-old and a full-time student </div>
                         <div className="grid-child-element"> <img src={Arrow} className="arrow"/> </div>
                         <div className="grid-child-element">Physically or mentally disabled and completely dependent on you</div>
                         <div className="grid-child-element"> <img src={Arrow} className="arrow"/> </div>
                         <div className="grid-child-element">Stillborn after 26 weeks of pregnancy. </div>

                     </div>

                     <p className="Drawer-sub-heading"> If your child: </p>
                     <div className="grid-container-element">
                         <div className="grid-child-element"> <img src={Arrow} className="arrow"/> </div>
                         <div className="grid-child-element">Gets married </div>
                         <div className="grid-child-element"> <img src={Arrow} className="arrow"/> </div>
                         <div className="grid-child-element">Become financially independent they will stop being insured under Children cover.  </div>
                         <div className="grid-child-element">  </div>
                         <div className="grid-child-element"> When your child can no longer be insured under Children cover, they can be insured under Child for life cover on your plan. </div>

                     </div>
                     <p className="Drawer-heading">Child for life cover </p>
                     <p className="Drawer-sub-heading">If you insure your child under Child for life cover, the cover for your child will continue for life.
                         A child must be younger than 85 years old when:
                     </p>
                     <div className="grid-container-element">
                         <div className="grid-child-element"> <img src={Arrow} className="arrow"/> </div>
                         <div className="grid-child-element">You add them to your plan  </div>
                         <div className="grid-child-element"> <img src={Arrow} className="arrow"/> </div>
                         <div className="grid-child-element">You change their cover level  </div>
                         <div className="grid-child-element"> <img src={Arrow} className="arrow"/> </div>
                         <div className="grid-child-element">You add additional benefits. </div>
                     </div>
                 </div>) : (
                    <div>
                        You may cover up to a maximum of 20 lives (including the plan owner).
                        <ul>
                            <li>
                                You may cover up to 3 lives as life partners.
                            </li>
                            <li>
                                Your children count as a single life in respect to the overall total, irrespective of how many children are covered.
                            </li>
                            <li>
                                If you choose to cover a child as a child for life, that child counts as one life on your plan in respect to the total of 20.
                            </li>
                            <li>
                                You may cover up to 4 lives as your parents.
                            </li>
                            <li>
                                You may cover up to 9 lives as extended family members.
                            </li>
                        </ul>

                    </div>
                ))))}

                <div className="alignBtnCloseRight">
                    <button
                        className="MuiButtonBase-root MuiButton-root MuiButton-contained productButton closeDisclaimerButton  funeralSelfServiceButton"
                        tabIndex="0" type="button"><span className="MuiButton-label-close">Close<span
                        className="btnArrowClose"><img src={CloseArrow}/>
                                </span></span><span
                        className="MuiTouchRipple-root MuiButton-iconSizeMedium"></span></button>
                </div>
            </div>
           {/* <Divider/>
            <div className={classes.drawerContent}>
                <p className={classes.dialogFooter}>For more information, you can
                    <a
                        href={API_CONFIG.SHOPFRONT_URL.concat("funeral/UGC_funeral_2020_10.pdf")}
                        target="_blank" rel="noreferrer"> view the Metropolitan Funeral User Guide and Contract
                        here.</a>
                </p>
            </div>*/}
        </div>
    );
}
