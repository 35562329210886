import {useState, useEffect, useRef} from 'react'
import {useContext} from "react";
import {Context} from "../../stateContext/StateContext";
import QuoteService from "../../services/APIService";
import lifeTypeList from "../../assets/data/lifeTypeList.json";
import UtilService from "../../services/UtilService";
import _ from "lodash"
import moment from "moment";

import {trackPromise, usePromiseTracker} from "react-promise-tracker";

export const useFormContext = (submitCallback, validate) => {

    const [values, setValues] = useContext(Context); // Our values from Context

    let INITIAL_STATE = _.cloneDeep(values);

    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState({});
    const [loading, setLoading] = useState(false);
    //let isSubmitting = false;
    const debouncedValues = useDebounce(values, 1)
    const {promiseInProgress} = usePromiseTracker();
    const currentStateRef = useRef();
    const resetState = () => {
        setValues({values, ...INITIAL_STATE});
    }


    useEffect(() => {
        setLoading(true)
        trackData(values).finally(() => setLoading(false));

    }, [null]);

    async function trackData(values) {
        // if (JSON.stringify(debouncedValues) !== JSON.stringify(values)) {
        if ((JSON.stringify(currentStateRef.current) !== JSON.stringify(values))) {
            try {
                let temp_Values = _.cloneDeep(values)
                await QuoteService.MFPWebTrackmetadata(temp_Values)
            } catch (error) {

            }
        }
    }

    const handleChange = (event, section, index) => {
        //event.persist();

        let value;
        let name;
        if ((typeof event !== "undefined") && (typeof event.target !== "undefined")) {
            const target = event.target;
            value = ((target.type === "checkbox")) ? target.checked : target.value;
            const inputName = target.id ? target.id : target.name;
            name = inputName;
            let nameSplitArray = inputName.split("_");
            if ((typeof nameSplitArray !== "undefined") && (nameSplitArray.length > 0)) {
                name = nameSplitArray[0]
            }
        } else {

            value = event;
            name = "dateOfBirth";

        }


        let temp_Values = _.cloneDeep(values)

        if (typeof index !== "undefined") {
            if (typeof section !== "undefined") {
                /*if ((typeof temp_Errors !== "undefined") && (typeof temp_Errors[section] !== "undefined") && (typeof temp_Errors[section][index] !== "undefined")&& (typeof temp_Errors[section][index][name] !== "undefined")){
                    delete temp_Errors[section][index][name];
                }*/
                if (section === "additionalLives") {
                    if (name === "lifeType") {
                        if (value !== temp_Values.root_state.additionalLives[index][name]) {
                            switch (temp_Values.root_state.additionalLives[index][name]) {
                                case "GUP":
                                    temp_Values.app_state.GUPCount--;
                                    break;
                                case "GULP":
                                    temp_Values.app_state.GULPCount--;
                                    break;
                                case "GUC":
                                    temp_Values.app_state.GUCCount--;
                                    break;
                                case "GUCL":
                                    temp_Values.app_state.GUCLCount--;
                                    break;
                                case "GUEF":
                                    temp_Values.app_state.GUEFCount--;
                                    break;
                                default:
                                    break;

                            }
                            switch (value) {
                                case "GUP":
                                    temp_Values.app_state.GUPCount++;
                                    break;
                                case "GULP":
                                    temp_Values.app_state.GULPCount++;
                                    break;
                                case "GUC":
                                    temp_Values.app_state.GUCCount++;
                                    break;
                                case "GUCL":
                                    temp_Values.app_state.GUCLCount++;
                                    break;
                                case "GUEF":
                                    temp_Values.app_state.GUEFCount++;
                                    break;
                                default:
                                    break;

                            }
                            temp_Values.root_state.additionalLives[values.app_state.additionalLifeIndex].relation = lifeTypeList[UtilService.findByAttr(lifeTypeList, "lifeType", value)].defaultRelation;
                        }

                    }
                    if (name === "disability") {
                        temp_Values.root_state.additionalLives[index].dependentOnParents = value;
                    }

                    if (name === "coverAmount") {
                        if ((temp_Values.root_state.additionalLives[index].lifeType === "GUC")) {
                            temp_Values.root_state.additionalLives.forEach((item, currentIndex) => {
                                if (item.lifeType === "GUC") {

                                    temp_Values.root_state.additionalLives[currentIndex].coverAmount = value;

                                      }
                            })
                        }
                    }
                    temp_Values.root_state.additionalLives[index][name] = value;
                    if (name === "coverAmount"){
                        temp_Values.root_state.totalCoverForInsuredLives = UtilService.getTotalCoverOfMembers(_.cloneDeep(temp_Values));
                    }

                    setValues(temp_Values);

                    if (value && (typeof errors !== "undefined") && errors.hasOwnProperty("additionalLives") && (typeof errors.additionalLives[index] !== "undefined") && errors.additionalLives[index].hasOwnProperty(name)) {
                        delete errors.additionalLives[index][name];
                    }
                } else if (section === "beneficiaries") {

                    temp_Values.root_state.beneficiaries[index][name] = value;
                    setValues(temp_Values);

                    if (value && (typeof errors !== "undefined") && errors.hasOwnProperty("beneficiaries") && (typeof errors.beneficiaries[index] !== "undefined") && errors.beneficiaries[index].hasOwnProperty(name)) {
                        delete errors.beneficiaries[index][name];
                    }
                }
            }
        } else {
            //index undefined
            if (typeof section !== "undefined") {
                /*if ((typeof temp_Errors !== "undefined") && (typeof temp_Errors[section] !== "undefined") && (typeof temp_Errors[section][name] !== "undefined")){
                    delete temp_Errors[section][name];
                }*/
                if (section === "mainMember") {
                    if (name === "initials") {
                        temp_Values.root_state.mainMember[name] = value.trim();
                    } else if (name === "dateOfBirth") {
                        temp_Values.root_state.mainMember[name] = moment(value).format('YYYY-MM-DD');
                    } else {
                        temp_Values.root_state.mainMember[name] = value;
                        if (name === "coverAmount")
                        {
                            temp_Values.root_state.totalCoverForInsuredLives = UtilService.getTotalCoverOfMembers(_.cloneDeep(temp_Values));
                        }
                    }

                    if (name === "firstName") {
                        if (value.length > 0) {
                            let initalsArray = value.split(" ");
                            let initals = "";
                            initalsArray.forEach((item) => {
                                initals = initals.concat(item.charAt(0));
                            })
                            temp_Values.root_state.mainMember.initials = initals;
                        }
                    }

                } else if (section === "bankDetails") {
                    temp_Values.root_state.bankDetails[name] = value;
                } else if (section === "app_state") {
                    temp_Values.app_state[name] = value;
                    if (name === "planType") {
                        /*if ((value === "Yourself&YourFamily")){
                            if ( temp_Values.root_state.mainMember.coverAmount === null) {
                                temp_Values.root_state.mainMember.coverAmount = 5000
                            }
                        }else */
                        if (value === "YourFamily") {
                            temp_Values.root_state.mainMember.coverAmount = null
                        }
                    }
                }
            } else {

                temp_Values.root_state[name] = value;

                    if(value === "Flexible Payment")
                    {
                        if(temp_Values.app_state.flexiblePaymentOption === null)
                        temp_Values.app_state.flexiblePaymentOption = "eft"
                    }
                if(value === "Debit Order")
                {temp_Values.app_state.flexiblePaymentOption = null}

            }
            setValues(temp_Values)

            if ((value) && (typeof errors[section] !== "undefined") && (errors[section].hasOwnProperty(name))) {
                delete errors[section][name];
            }
            if ((value) && (errors.hasOwnProperty(name))) {
                delete errors[name];
            }
        }
     //  trackData(temp_Values).finally(() => setLoading(false));
    };


    const setErrorsExport = (newErrors) => {
        setErrors(newErrors);
    };

    const handleBlur = async (event, section, index) => {
        if (event) event.preventDefault();
        const target = event.target;
        const name = target.id ? target.id : target.name;
        let value = ((target.type === "checkbox")) ? target.checked : target.value;
        let temp_Values = _.cloneDeep(values);
        let temp_Errors = _.cloneDeep(errors);
        if (name === "whatsappConsent") {
        }

        if (name === "age") {
            let temp_Values = _.cloneDeep(values);
            if (typeof section !== "undefined") {
                if ((typeof index !== "undefined") && (index !== -1)) {
                    if (section === "additionalLives") {
                        temp_Values.root_state.additionalLives[index][name] = Number(value).toString();
                    }
                    if (section === "beneficiaries") {
                        temp_Values.root_state.additionalLives[index][name] = Number(value).toString();
                    }
                } else {
                    temp_Values.root_state[section][name] = Number(value).toString();
                }
            } else {
                temp_Values.root_state[name] = Number(value).toString();
            }
        }
        if (typeof index !== "undefined") {
            //delete errors.relMembers[index][name];
            if (validate) {
                const e = await validate(values, section, index, name, errors, validated);
                if (typeof e.errors !== "undefined") {
                    setErrors(errors => ({...errors, ...e.errors}));
                    if (typeof e.validated !== "undefined") {
                        setValidated(validated => ({...validated, ...e.validated}));
                    }
                } else {
                    setErrors(errors => ({...errors, ...e}));
                }

            }
        } else {
            delete errors[name];
            if (validate) {
                const e = await validate(values, section, index, name, errors, validated);
                if (typeof e.errors !== "undefined") {
                    setErrors(errors => ({...errors, ...e.errors}));
                } else {
                    if ((typeof section !== "undefined") && (section === "mainMember")) {
                        console.log(JSON.stringify(e))
                        if ((name === "cellphone") && ((typeof e.mainMember === "undefined") || ((typeof e.mainMember !== "undefined") && (typeof e.mainMember[name] === "undefined")))){
                            if ((values.root_state.mainMember.contactDetails.length === 0) || ((values.root_state.mainMember.contactDetails.length > 0) && (UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone) === -1))) {
                                _.map(values.root_state.mainMember.contactDetails, function(x) {
                                    if (x.contactType === "Cellphone") {
                                        x.active = false;
                                    }
                                    return x
                                });
                                values.root_state.mainMember.contactDetails.push({
                                    contactType: "Cellphone",
                                    contactValue: values.root_state.mainMember.cellphone,
                                    active: true,
                                    validated: false
                                })
                            }
                            else {
                                _.map(values.root_state.mainMember.contactDetails, function(x) {
                                    if (x.contactType === "Cellphone") {
                                        x.active = false;
                                    }
                                    return x
                                });
                                values.root_state.mainMember.contactDetails[UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone)].active = true;
                            }
                        }
                        if ((name === "email") && ((typeof e.mainMember === "undefined") || ((typeof e.mainMember !== "undefined") && (typeof e.mainMember[name] === "undefined")))){
                            if ((values.root_state.mainMember.contactDetails.length === 0) || ((values.root_state.mainMember.contactDetails.length > 0) && (UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.email) === -1))) {
                                _.map(values.root_state.mainMember.contactDetails, function(x) {
                                    if (x.contactType === "Email") {
                                        x.active = false;
                                    }
                                    return x
                                });
                                values.root_state.mainMember.contactDetails.push({
                                    contactType: "Email",
                                    contactValue: values.root_state.mainMember.email,
                                    active: true,
                                    validated: false
                                })
                            }
                            else {
                                _.map(values.root_state.mainMember.contactDetails, function(x) {
                                    if (x.contactType === "Email") {
                                        x.active = false;
                                    }
                                    return x
                                });
                                values.root_state.mainMember.contactDetails[UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.email)].active = true;
                            }
                        }
                        if ((name === "idNumber") && ((typeof e.mainMember === "undefined") || ((typeof e.mainMember !== "undefined") && (typeof e.mainMember[name] === "undefined")))){
                            if ((values.root_state.mainMember.knownConsumerNumber !== null)){
                                await QuoteService.UpdateKnownConsumer(temp_Values)
                                    .then(r => {
                                    }).catch(e => {});
                            }
                            else {
                                await QuoteService.CreateKnownConsumer(temp_Values)
                                    .then(r => {
                                    }).catch(e => {});
                            }
                        }
                    }
                    setErrors(errors => ({...errors, ...e}));
                }
            }
        }
        trackData(temp_Values).finally(() => setLoading(false));
    };

    const handleSubmit = async (event, section, index) => {

        let e = {};
        if (event) event.preventDefault();

        let temp_Values = _.cloneDeep(values);
        let temp_Errors = _.cloneDeep(errors);
        if (typeof index !== "undefined") {
            if (validate) {
                 e = await validate(values,section, index, null, errors, validated);

                if (typeof e.errors !== "undefined") {
                    setErrors(errors => ({...errors, ...e.errors}));
                } else {
                    setErrors(errors => ({...errors, ...e}));
                }
            }
        } else {
            if (validate) {
                e = await validate(values, section, null, null, errors, validated);

                if (typeof e.errors !== "undefined") {
                    setErrors(errors => ({...errors, ...e.errors}));
                } else {
                    setErrors(errors => ({...errors, ...e}));
                }
            }
        }

        if (submitCallback && ((Object.keys(errors).length === 0))) {
            submitCallback();
        }
    };

    //const currentStateRef = useRef();


    return [
        values,
        errors,
        resetState,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        setErrorsExport,
        validated
    ];
}

/**
 * Debounce a function by time
 * @param {Function} func
 * @param {Number} delay
 */

// Our hook
export function useDebounce(value, delay) {
    // State and setters for debounced value
    //  const refval = useRef(value)
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Set debouncedValue to value (passed in) after the specified delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Return a cleanup function that will be called every time ...
            // ... useEffect is re-called. useEffect will only be re-called ...
            // ... if value changes (see the inputs array below).
            // This is how we prevent debouncedValue from changing if value is ...
            // ... changed within the delay period. Timeout gets cleared and restarted.
            // To put it in context, if the user is typing within our app's ...
            // ... search box, we don't want the debouncedValue to update until ...
            // ... they've stopped typing for more than 500ms.
            return () => {
                clearTimeout(handler);
            };
        },
        // Only re-call effect if value changes
        // You could also add the "delay" var to inputs array if you ...
        // ... need to be able to change that dynamically.
        [value, delay]
    );

    return debouncedValue;
}



