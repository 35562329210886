let API_URL;
let SHOPFRONT_URL;
let FUNERALPLANNER_URL;
let PROFILE_URL;
let MET_URL;
let HOME_URL;
let FUNERAL_PLANER;
let CONTACTUS_URL;
let CONTENTSHOPFRONT_URL;
let FUNERAL_EVENT_PLANNER_URL;


const host = window && window.location && window.location.hostname;
API_URL = process.env.REACT_APP_API_URL;
CONTENTSHOPFRONT_URL=process.env.REACT_APP_CONTENTSHOPFRONT_URL;
SHOPFRONT_URL = process.env.REACT_APP_SHOPFRONT_URL;
PROFILE_URL = process.env.REACT_APP_PROFILE_URL;
FUNERALPLANNER_URL = process.env.REACT_APP_FUNERALPLANNER_URL;
FUNERAL_EVENT_PLANNER_URL = process.env.REACT_APP_FUNERAL_EVENT_PLANNER_URL;
HOME_URL=process.env.REACT_APP_HOME_URL;
CONTACTUS_URL=process.env.REACT_APP_CONTACTUS_URL;
MET_URL = process.env.REACT_APP_MET_URL;
/*
if(host === "localhost"){
     //API_URL = "https://localhost:9260";
     API_URL = "https://api-dev.getup.metropolitan.co.za";
     //API_URL = "https://api.getup.metropolitan.co.za";
     CONTENTSHOPFRONT_URL="https://content.metgetup.co.za"
     SHOPFRONT_URL = "https://develop1-0.d2omnxwob7neqd.amplifyapp.com/";
     PROFILE_URL = "https://www.mymetgetup-dev.co.za/";
     FUNERALPLANNER_URL = "https://www.metropolitan.co.za/funeral/";
     FUNERAL_EVENT_PLANNER_URL = "https://www.funeralplannermetgetup-dev.co.za/?id=met";
     HOME_URL="https://www.metropolitan.co.za/"
     CONTACTUS_URL="https://www.metropolitan.co.za/contact-us/"


}
else if(host === "www.metropolitaninsurance.co.za"){
     API_URL = "https://api.getup.metropolitan.co.za";
     SHOPFRONT_URL = "https://www.metgetup.co.za/"
     CONTENTSHOPFRONT_URL="https://content.metgetup.co.za"
     MET_URL = "https://www.metropolitan.co.za"
     PROFILE_URL = "https://www.mymetgetup.co.za/";
     FUNERALPLANNER_URL = "https://www.metropolitan.co.za/funeral/";
     FUNERAL_EVENT_PLANNER_URL = "https://www.funeralplannermetgetup.co.za/?id=met";
     HOME_URL="https://www.metropolitan.co.za/"
     CONTACTUS_URL="https://www.metropolitan.co.za/contact-us/"
}
else
{
     API_URL = "https://api-dev.getup.metropolitan.co.za";
     SHOPFRONT_URL = "https://develop1-0.d2omnxwob7neqd.amplifyapp.com/";
     PROFILE_URL = "https://www.mymetgetup-dev.co.za/";
     FUNERALPLANNER_URL = "https://www.metropolitan.co.za/funeral/";
     FUNERAL_EVENT_PLANNER_URL = "https://www.funeralplannermetgetup-dev.co.za/?id=met";
     CONTENTSHOPFRONT_URL="https://content.metgetup.co.za"
     HOME_URL="https://www.metropolitan.co.za/"
     CONTACTUS_URL="https://www.metropolitan.co.za/contact-us/"
}*/

export const API_CONFIG = {API_URL: API_URL, SHOPFRONT_URL: SHOPFRONT_URL, FUNERALPLANNER_URL: FUNERALPLANNER_URL, PROFILE_URL: PROFILE_URL,HOME_URL:HOME_URL,CONTACTUS_URL:CONTACTUS_URL, CONTENTSHOPFRONT_URL:CONTENTSHOPFRONT_URL, FUNERAL_EVENT_PLANNER_URL: FUNERAL_EVENT_PLANNER_URL};
