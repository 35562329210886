import './components/customHooks/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
/*import 'bootstrap/dist/css/bootstrap.min.css';*/
import {BrowserRouter} from "react-router-dom";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';


ReactDOM.render(
    <BrowserRouter>
        {/*<StrictMode>*/}
            <App/>
        {/*</StrictMode>*/}
    </BrowserRouter>
   , document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorkerRegistration.register();

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
