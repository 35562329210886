import lifeTypeList from "../../assets/data/lifeTypeList.json";
import UtilService from "../../services/UtilService";

export function mainMemberDialogValidation(values, section, externalIndex, name, errorsFromContext) {


    let errors = errorsFromContext;

    const cellphonePattern = /^((?:\+27|27)|0)(\d{2})-?(\d{3})-?(\d{4})$/;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const pattern = /^[A-Za-zÀ-ÖØ-öø-ÿ '-]+$/
    if (typeof errors === "undefined") {
        errors = {};
    }

    if (typeof errors.mainMember === "undefined") {
        errors.mainMember = {};
    }

        switch (name) {
            case "lastName":
                if (typeof values.root_state.mainMember.lastName === "undefined" || values.root_state.mainMember.lastName.length === 0) {
                    errors.mainMember.lastName = "Required";
                } else if (!pattern.test(values.root_state.mainMember.lastName)) {
                    errors.mainMember.lastName = "Invalid characters";
                } else {
                    delete errors.mainMember["lastName"];
                }
                break;
            case "firstName":
                if (!values.root_state.mainMember.firstName || values.root_state.mainMember.firstName.length === 0) {
                    errors.mainMember.firstName = "Required ";
                } else if (!pattern.test(values.root_state.mainMember.firstName)) {
                    errors.mainMember.firstName = "Invalid characters";
                } else {
                    delete errors.mainMember["firstName"];
                }
                break;
            case 'cellphone' :
                if (!values.root_state.mainMember.cellphone || (values.root_state.mainMember.cellphone.length === 0)) {
                    errors.mainMember.cellphone = "Please enter your preferred cellphone number.";
                }
                else if (!cellphonePattern.test(values.root_state.mainMember.cellphone)) {
                    errors.mainMember.cellphone = "The cellphone number provided seems to be in an incorrect format.";
                } else {
                    delete errors.mainMember["cellphone"];
                   /* delete errors.mainMember["contactDetails"];*/
                   /* if ((cellphonePattern.test(values.root_state.mainMember.cellphone)) && (values.root_state.mainMember.contactDetails.length === 0) || ((values.root_state.mainMember.contactDetails.length > 0) && (UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone) === -1))) {
                        values.root_state.mainMember.contactDetails.push({
                            contactType: "Cellphone",
                            contactValue: values.root_state.mainMember.cellphone,
                            active: true,
                            validated: false
                        })
                    }*/

                }
                break;
            case 'email' :
                if (!(!values.root_state.mainMember.email || (values.root_state.mainMember.email.length === 0)) && (!emailPattern.test(values.root_state.mainMember.email))) {
                    errors.mainMember.email = "Invalid email address";
                } else {
                    delete errors.mainMember["email"];
                    delete errors.mainMember["contactDetails"];
                    /*if ((values.root_state.mainMember.contactDetails.length === 0) || ((values.root_state.mainMember.contactDetails.length > 0) && (UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.email) === -1))) {
                        values.root_state.mainMember.contactDetails.push({
                            contactType: "Email",
                            contactValue: values.root_state.mainMember.email,
                            active: true,
                            validated: false
                        })
                    }*/
                }
                break;
            default:
                if (!values.root_state.mainMember.lastName || values.root_state.mainMember.lastName.length === 0) {
                    errors.mainMember.lastName = "Required";
                } else if (!pattern.test(values.root_state.mainMember.lastName)) {
                    errors.mainMember.lastName = "Invalid characters";
                } else {
                    delete errors.mainMember.lastName;
                }

                if (!values.root_state.mainMember.firstName || values.root_state.mainMember.firstName.length === 0) {
                    errors.mainMember.firstName = "Required ";
                } else if (!pattern.test(values.root_state.mainMember.firstName)) {
                    errors.mainMember.firstName = "Invalid characters";
                } else {
                    delete errors.mainMember["firstName"];
                }

                if (!values.root_state.mainMember.cellphone || (values.root_state.mainMember.cellphone.length === 0)) {
                    errors.mainMember.cellphone = "Please enter your preferred cellphone number.";
                }
                else if (!cellphonePattern.test(values.root_state.mainMember.cellphone)) {
                    errors.mainMember.cellphone = "The cellphone number provided seems to be in an incorrect format.";
                } else {
                    delete errors.mainMember["cellphone"];

                   /* if ((values.root_state.mainMember.contactDetails.length === 0) || ((values.root_state.mainMember.contactDetails.length > 0) && (UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone) === -1))) {
                        values.root_state.mainMember.contactDetails.push({
                            contactType: "Cellphone",
                            contactValue: values.root_state.mainMember.cellphone,
                            active: true,
                            validated: false
                        })
                    }*/

                }
                break;
        }


    if (typeof errors.mainMember !== "undefined") {
        if (Object.keys(errors.mainMember).length === 0) {
            delete errors.mainMember
        }
    }
    //console.log(JSON.stringify(errors))
    return errors;
}


