import {Container, Grid, makeStyles, Typography} from "@material-ui/core";
import React, { useEffect, useState} from "react";
/*import '../assets/css/style.css'*/

import {wildcardMatch} from '../components/functions/wildcardMatch'
import { useLocation} from "react-router-dom";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import loadable from '@loadable/component';
import {useFormContext} from "./customHooks/useFormContext";
const useStyles = makeStyles(theme => ({
    cardGrid: {
        /*paddingTop: theme.spacing(4),*/
        /*paddingBottom: theme.spacing(2),*/


    },

    root: {
        flexGrow: 1,
        marginTop: theme.spacing(4),
        // marginBottom: theme.spacing(8),
       /* backgroundColor: '#F2FEF6',*/

    },

    headingBlackText: {
        fontSize: '36px',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        boxSizing: 'border-box',
        fontFamily: 'Hind Siliguri',
        fontWeight: 'bold',
        color: '#1e1e1e',
        lineHeight: 'normal',
        textAlign: 'left',
        // paddingTop: '5%',
    },

}));

const BreadCrumbsStepper = loadable(() => import("./BreadCrumbsStepper"));
const HeaderBanner = () => {
    const [
        values,
        errors,
        resetState,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit
    ] = useFormContext(null, null);
    const classes = useStyles();
    const location = useLocation();
    const [txt1, setTxt1] = useState('Buy Metropolitan');

    const txt2 = (values.app_state.fusion === false) ? "FUNERAL COVER" : "FUNERAL FUSION";
    const txt3='';
    const [disableHeader, setDisableHeader] = useState(true);
    const [disableBreadCrumb, setDisableBreadCrumb] = useState(true);
    const theme = useTheme();
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {

 /*       console.log("============================================================================")
        console.log("LOCATION CHANGED: " + location.pathname.toString() )
        console.log("============================================================================")*/
        if (wildcardMatch(location.pathname.toString().toLowerCase(), "/quote/*")) {
            setDisableHeader(true);
            setDisableBreadCrumb(true);

        }
        else if (wildcardMatch(location.pathname.toString().toLowerCase(), "/buy/*")) {
            setDisableHeader(false);
            setDisableBreadCrumb(false);

        }
        else if (wildcardMatch(location.pathname.toString().toLowerCase(), "/final/*")) {
            setTxt1('Metropolitan');
            setDisableHeader(false);
            setDisableBreadCrumb(true);

        }
        else {
            setDisableHeader(true);
            setDisableBreadCrumb(true);

        }

    }, [location.pathname])

    return (

        <React.Fragment>
            {!disableHeader && (
                <div className={`headerstyle ${mobileScreen && ("mobile")}`} >

                </div>)}
            {!disableBreadCrumb && (
                <React.Fragment>
                <Container className={classes.cardGrid}  >
                    <Grid  container justifyContent="space-around" direction="row">
                        <Grid item md={12} xs={12} >

                            <BreadCrumbsStepper/>


                        </Grid>
                    </Grid>
                </Container>

            </React.Fragment>)}

        </React.Fragment>
    )
}

export default HeaderBanner
