import React, {Suspense, useEffect} from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {createMuiTheme, createTheme} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CssBaseline from "@material-ui/core/CssBaseline";
import {StateContext} from "./stateContext/StateContext";
import usePageTracking from "./components/customHooks/usePageTracking";
import {Container, Grid, MuiThemeProvider} from "@material-ui/core";
import loadable from '@loadable/component';
import 'font-awesome/css/font-awesome.min.css';
import "./assets/css/style.css";

import SidePanelLayout from "./components/layouts/sidePanelLayout";
import {Beforeunload} from "react-beforeunload";
import {confirmAlert} from "react-confirm-alert";

const PaymentOptions = loadable(() => import("./views/onboarding/paymentOptions/paymentOptions"));
const CreditCardPayment = loadable(() => import("./views/onboarding/creditcardform/CreditCardPayment"));
const PersonalInfoForm = loadable(() => import("./views/onboarding/personalInfoForm/personalInfoForm"));
const LegalComplianceForm = loadable(() => import("./views/onboarding/legalComplianceInfoForm/LegalComplianceForm"));
const PolicySummaryForm = loadable(() => import("./views/onboarding/policySummaryForm/PolicySummaryForm"));
const HeaderBanner = loadable(() => import("./components/HeaderBanner"));
const BeneficiaryInfo = loadable(() => import("./views/onboarding/beneficiaryInfoDetails/BeneficiaryInfo"));
const AddLives = loadable(() => import("./views/quote/steps/quizQuote/additionalLife/AddLives"));
const NotFound = loadable(() => import("./views/NotFound"));
const Cart = loadable(() => import("./views/quote/steps/quizQuote/cart/cart"));
const MainMemberPersonalDetails = loadable(() => import("./views/quote/steps/quizQuote/mainMember/MainMemberPersonalDetails"));
const PaymentInfoForm = loadable(() => import("./views/onboarding/debitOrderForm/DebitOrderForm"));
const PaymentInfoFormOld = loadable(() => import("./views/onboarding/paymentInfoForm/paymentInfoForm"));
const PlanType = loadable(() => import("./views/quote/steps/quizQuote/selectPlanType/PlanType"));
const AdditionalLifePersonalDetails
    = loadable(() => import("./views/quote/steps/quizQuote/additionalLife/AdditionalLifePersonalDetails"));

const MainMemberCoverAmount = loadable(() => import("./views/quote/steps/quizQuote/mainMember/MainMemberCoverAmount"));
const AddBenefit = loadable(() => import("./views/quote/steps/quizQuote/addBenefit/AddBenefit"));
const AdditionalLifeCoverAmount = loadable(() => import("./views/quote/steps/quizQuote/additionalLife/AdditionalLifeCoverAmount"));
const ContactInfo = loadable(() => import("./views/onboarding/contactInfoDetails/ContactInfo"));
const AdditionalLifeMedicalAid = loadable(() => import("./views/quote/steps/quizQuote/additionalLife/AdditionalLifeMedicalAid"));
const AdditionalLifeProvince = loadable(() => import("./views/quote/steps/quizQuote/additionalLife/AdditionalLifeProvince"));
const AdditionalLifeBiologicalChildCheck
    = loadable(() => import("./views/quote/steps/quizQuote/additionalLife/AdditionalLifeBiologicalChildCheck"));
const AdditionalLifeChildCheckList = loadable(() => import("./views/quote/steps/quizQuote/additionalLife/AdditionalLifeChildCheckList"));

const Layout = loadable(() => import("./components/layouts/layout"));
const FinalConfirmationForm_Func = loadable(() => import("./views/onboarding/FinalConfirmationForm_Func"));
const renderLoader = () => <React.Fragment>
    <Container className={"mainContentNoButton"} maxWidth="lg"
               style={{marginTop: "17px", marginBottom: "60px"}}
    > <Grid container spacing={1} style={{marginTop: '30px'}}>
        Loading...
    </Grid>


    </Container></React.Fragment>;
const App = () => {

    const theme = createTheme({
        palette: {
            primary: {
                light: '#6875ff',
                main: '#4353ff',
                dark: '#6637f4',
                contrastText: '#fff',
            },

            secondary: {
                light: 'rgba(67, 83, 255, 0.6)',
                main: '#6637f4',
                dark: '#6637f4',
                contrastText: '#fff',
            },
        },
    });




   /* usePageTracking('UA-78966334-2');*/


    return (
        /* <Suspense fallback={renderLoader()}>*/
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <React.Fragment>
                    <CssBaseline/>
                    {/*end header nav*/}
                    <StateContext>
                        <Beforeunload onBeforeunload={() => 'You’ll lose your data!'}>
                            <Layout>

                                <SidePanelLayout>
                                    <Suspense fallback={renderLoader()}>
                                        <Switch>

                                            <Route exact path='/' component={MainMemberPersonalDetails}/>
                                            <Route exact path='/quote/about-you/detail'
                                                   component={MainMemberPersonalDetails}/>
                                            <Route exact path='/quote/about-you/cover-amount'
                                                   component={MainMemberCoverAmount}/>
                                            <Route exact path='/quote/cover/options' component={PlanType}/>

                                            <Route exact path='/quote/about-them/detail'
                                                   component={AdditionalLifePersonalDetails}/>
                                            <Route exact path='/quote/about-them/confirm-legal-child'
                                                   component={AdditionalLifeBiologicalChildCheck}/>
                                            <Route exact path='/quote/about-them/child-checklist'
                                                   component={AdditionalLifeChildCheckList}/>
                                            <Route exact path='/quote/about-them/province'
                                                   component={AdditionalLifeProvince}/>
                                            <Route exact path='/quote/about-them/medical-aid'
                                                   component={AdditionalLifeMedicalAid}/>
                                            <Route exact path='/quote/about-them/cover-amount'
                                                   component={AdditionalLifeCoverAmount}/>
                                            <Route exact path='/quote/add-life' component={AddLives}/>
                                            <Route exact path='/quote/cart' component={Cart}/>
                                            <Route exact path='/buy/step-1' component={PersonalInfoForm}/>
                                            <Route exact path='/buy/step-2' component={BeneficiaryInfo}/>
                                            <Route exact path='/buy/step-3' component={LegalComplianceForm}/>
                                            <Route exact path='/buy/confirm' component={PolicySummaryForm}/>
                                              <Route exact path='/buy/checkout' component={PaymentInfoFormOld}/>
                                            <Route exact path='/buy/checkout/payment-options'
                                                   component={PaymentOptions}/>
                                            <Route exact path='/buy/checkout/debit-order' component={PaymentInfoForm}/>
                                            <Route exact path='/buy/checkout/credit-card'
                                                   component={CreditCardPayment}/>
                                            {/*<Route exact path='/success' component={FinalConfirmationForm_Func}/>*/}
                                            <Route exact path='/final/success' component={FinalConfirmationForm_Func}/>
                                            <Route exact path='/final/validating'
                                                   component={FinalConfirmationForm_Func}/>
                                            <Route exact path='/oops' component={NotFound}/>
                                            <Route exact path='/underconstruction' component={NotFound}/>
                                            <Route path='*' exact={true} component={MainMemberPersonalDetails}/>
                                        </Switch>
                                    </Suspense>
                                </SidePanelLayout>
                            </Layout>
                        </Beforeunload>
                    </StateContext>
                </React.Fragment>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>

    );
}

export default App
// export default RequestCheckInterceptor(App);
